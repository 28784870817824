import React, { useState, useEffect} from 'react';
import { useGetImagePendingQuery, useStatusImageMutation, useRateImageMutation } from '../../services/api'; // adjust path as needed
import { Button, Rate, Space, Image, Card, Typography, Flex, Descriptions } from 'antd';
const { Text } = Typography;

const ReviewPage = () => {
    const [rating, setRating] = useState(undefined);

  const { data: image, isLoading, error, refetch } = useGetImagePendingQuery();
  const [statusImage, { isLoading: isStatusUpdating }] = useStatusImageMutation();
  const [rateImage, { isLoading: isRating }] = useRateImageMutation();

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>No images available</div>;

  const handleAccept = async () => {
    try {
        await statusImage({ status: "accepted", id: image?.id }).unwrap();
        setRating(undefined);
        refetch();
    } catch (err) {
        console.error('Failed to update status:', err);
    }
  };

  const handleDecline = async () => {
    try {
        await statusImage({ status: "declined", id: image?.id }).unwrap();
        setRating(undefined);
        refetch();
    } catch (err) {
        console.error('Failed to update status:', err);
    }
  };

  const handleRate = async (value) => {
    try {
        // if (value === 0) { value = null }
        setRating(value);
        await rateImage({ rating: value, id: image?.id }).unwrap();
        
    } catch (err) {
        console.error('Failed to update rating:', err);
    }
  };


  return (
    <Card style={{ maxWidth: 600, margin: '4px auto' }}
        description={image.title}
    >
      <Image
        preview={false}
        src={image.image}
        alt={image.title || 'Image for review'}
        style={{ width: '100%' }}
      />
      <Descriptions title={image.title} column={1}>
        <Descriptions.Item label="Slogan">{image?.tags?.slogan}</Descriptions.Item>
        <Descriptions.Item label="Image description">{image?.tags?.animal_description}</Descriptions.Item>
      </Descriptions>
      <Space direction="vertical" style={{ width: '100%', marginTop: 16 }}>
        <Flex align="center" justify='center'>
            <Space>
                <Rate value={rating ?? image?.rating} count={10} onChange={handleRate} />
                <Text strong>{rating ?? image?.rating}</Text>
            </Space>
        </Flex>
        <Flex align="center" justify='center'>
            <Space>
                <Button danger onClick={handleDecline}>
                    Decline
                </Button>   
                <Button 
                    disabled={rating === 0 || rating === undefined || isStatusUpdating} 
                type="primary" 
                onClick={handleAccept}
                >
                    Accept
                </Button>
            </Space>
        </Flex>
        
      </Space>
    </Card>
  );
};

export default ReviewPage; 