import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout as AntLayout } from 'antd';
import Header from './Header';

const { Content } = AntLayout;

const Layout = () => {
  return (
    <AntLayout className="min-h-screen">
      <Header />
      <Content >
        <Outlet />
      </Content>
    </AntLayout>
  );
};

export default Layout;
