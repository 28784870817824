import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Layout from './components/Layout/Layout';
import LoginPage from './pages/LoginPage';
import GalleryPage from './pages/GalleryPage';
import ImagePage from './pages/ImagePage';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import ReviewPage from './components/Review/ReviewPage';

function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={
            isAuthenticated ? <Navigate to="/gallery" replace /> : <LoginPage />
          }
        />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }
        >
          <Route path="/review" element={<ReviewPage />} />
          <Route index element={<Navigate to="/gallery" replace />} />
          <Route path="gallery" element={<GalleryPage />} />
          <Route path="image/:id" element={<ImagePage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App; 