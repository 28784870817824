import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetImageQuery } from '../services/api';
import { Typography, Spin } from 'antd';

const { Title } = Typography;

const ImagePage = () => {
  const { id } = useParams();
  const { data: image, isLoading } = useGetImageQuery(id);

  if (isLoading) {
    return (
      <div style={{ textAlign: 'center', marginTop: 32 }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div>
      <Title level={2}>Image Details</Title>
      {image && (
        <div>
          <img 
            src={image.url} 
            alt={image.title} 
            style={{ maxWidth: '100%', height: 'auto' }}
          />
          {/* Add your rating and action buttons here */}
        </div>
      )}
    </div>
  );
};

export default ImagePage;
