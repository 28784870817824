import { useState } from 'react';
import { useGetImagesQuery } from '../services/api';
import ImageCard from '../components/ImageGallery/ImageCard';
import { Button, Spin, Row, Col, Typography, Image } from 'antd';

const { Text } = Typography;

export default function GalleryPage() {
  const [page, setPage] = useState(1);
  const { data, isLoading, isFetching } = useGetImagesQuery(page);

  const loadMore = () => {
    setPage((prev) => prev + 1);
  };

  if (isLoading) return (
    <div style={{ textAlign: 'center', marginTop: 32 }}>
      <Spin size="large" />
    </div>
  );

  return (
    <div style={{ padding: 24 }}>
      <Row gutter={[16, 16]}>
        {data?.map((image) => (
          <Col xs={24} md={8} lg={4} key={image.id}>
            <Image src={image.image} />
          </Col>
        ))}
      </Row>
      {data?.next && (
        <Button
          onClick={loadMore}
          loading={isFetching}
          style={{ marginTop: 16 }}
        >
          Load More
        </Button>
      )}
    </div>
  );
}
