import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../config';
import { setCredentials } from '../features/auth/authSlice';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiUrl,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: '/token/pair',
        method: 'POST',
        body: credentials,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setCredentials(data));
        } catch (error) {
          console.error('Login failed:', error);
        }
      },
    }),
    getImages: builder.query({
      query: (page = 1) => `/v1/viewer/images`,
    }),
    getImage: builder.query({
      query: (id) => `/v1/viewer/image/${id}`,
    }),
    getImagePending: builder.query({
      query: (id) => `/v1/viewer/image/last-pending`,
    }),
    rateImage: builder.mutation({
      query: ({ id, rating }) => ({
        url: `/v1/viewer/image/${id}/rate`,
        method: 'PATCH',
        body: { rating },
      }),
    }),
    statusImage: builder.mutation({
      query: ({ id, status }) => ({
        url: `/v1/viewer/image/${id}/status`,
        method: 'PATCH',
        body: { status },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useGetImagesQuery,
  useGetImageQuery,
  useGetImagePendingQuery,
  useRateImageMutation,
  useStatusImageMutation,
} = api;
