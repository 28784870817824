import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../services/api';
import { Button, Input, Card, Alert, Typography, Space } from 'antd';

const { Title } = Typography;

export default function LoginPage() {
  const navigate = useNavigate();
  const [login, { isLoading, error }] = useLoginMutation();
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await login(credentials).unwrap();
      console.log('Login success:', result);
      navigate('/gallery');
    } catch (err) {
      console.error('Failed to login:', err);
    }
  };

  return (
    <div style={{ 
      minHeight: '100vh', 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center' 
    }}>
      <Card 
        title={<Title level={2} style={{ textAlign: 'center', margin: 0 }}>Login</Title>} 
        style={{ width: 350 }}
      >
        <form onSubmit={handleSubmit}>
          {error && (
            <Alert
              type="error"
              message={error.data?.detail || 'Login failed'}
              style={{ marginBottom: 16 }}
            />
          )}
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <Input
              placeholder="Username"
              value={credentials.username}
              onChange={(e) =>
                setCredentials({ ...credentials, username: e.target.value })
              }
            />
            <Input.Password
              placeholder="Password"
              value={credentials.password}
              onChange={(e) =>
                setCredentials({ ...credentials, password: e.target.value })
              }
            />
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              style={{ width: '100%' }}
            >
              Login
            </Button>
          </Space>
        </form>
      </Card>
    </div>
  );
}
