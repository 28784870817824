import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../features/auth/authSlice';
import { Button, Layout, Space } from 'antd';

const { Header: AntHeader } = Layout;

const Header = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <AntHeader style={{ 
      width: '100%', 
      background: '#fff',
      borderBottom: '1px solid #f0f0f0',
      padding: '0 24px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }}>
      <Space>
        <Link
          to="/gallery"
          style={{
            fontSize: '14px',
            fontWeight: 500,
            color: 'rgba(0, 0, 0, 0.88)',
          }}
        >
          Gallery
        </Link>
        <Link
          to="/review"
          style={{
            fontSize: '14px',
            fontWeight: 500,
            color: 'rgba(0, 0, 0, 0.88)',
          }}
        >
          Review Image
        </Link>
      </Space>
      <Button 
        onClick={handleLogout}
        danger
        size="small"
      >
        Logout
      </Button>
    </AntHeader>
  );
};

export default Header;
